*{
  margin:0;
  padding:0;
}
.invert{
  filter: invert(100%);
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem #FEB139;
}

.more-form:focus{
  box-shadow: 0 0 0 0.25rem #293462;
  
}